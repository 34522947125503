import { useState, useEffect } from 'react'
import qs from 'query-string'

const useQueryParams = <T extends {}>() => {
  const [params, setParams] = useState<T>()
  useEffect(() => {
    const parsed = qs.parse(window.location.search) as T
    setParams(parsed)
  }, [])

  return params
}

export default useQueryParams
