import styled from 'styled-components'

export const Wrapper = styled.div`
  text-align: center;
  padding-bottom: 5rem;

  p {
    margin-bottom: 3rem;
  }
`

export const Image = styled.div`
  max-width: 30rem;
  margin: 0 auto;
`
