import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import useQueryParams from 'hooks/use-query-params'
import Layout from 'components/layout'
import SEO from 'components/seo'
import SubscribeForm from 'components/subscribe-form'
import { Wrapper, Image } from './styles'

type QueryParams = {
  filename: string
}

const Download = () => {
  const params = useQueryParams<QueryParams>()

  return (
    <Layout>
      <SEO title="Download file" />
      <Wrapper>
        <Image>
          <StaticImage src="./attachment.png" alt="Attachment" />
        </Image>
        <h1>File: {params && params.filename}</h1>
        <p>
          Everybody wants your email. We do, too.
          <br />
          No spam ever, just quality content and access to all source files.
          <br />
        </p>
        <SubscribeForm resource={params && params.filename} list="framer-x" />
      </Wrapper>
    </Layout>
  )
}

export default Download
